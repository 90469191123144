.bottom-bar {
  margin: 0;
  padding: .8125rem;
  position: fixed;
  bottom: 3.125rem;
  width: 93%;
}

.img-item {
  padding: .625rem;
  border-radius: 1rem;
}