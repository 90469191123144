.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0em;
  background: #ffffff;
  box-shadow: 0px -1px 10px #828485;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

ul.footer-ul {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: .3125rem 0;
}

.footer-ul-li {
  list-style: none;
  display: flex;
  flex-flow: column;
  flex: 1;
  align-items: center;
  text-decoration: none;
  color: #8a8a8a;
  font-size: 2rem;
}

.footer-ul-li.active {
  color: #00adae;
}

.footer-ul-li span {
  color: #8a8a8a;
  font-size: .875rem;
}

.footer-ul-li.active span {
  color: #00adae;
}

.footer-ul-li img {
  width: 2rem;
}