.input-form {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 4rem;
  padding: 0 1rem;
  display: flex;

}

.list-msg {
  height: fit-content;
  min-height: 2.8125rem;
  overflow: hidden;
  margin-top: .625rem;
}

.outgoing {
  font-size: .9375rem;
  display: flex;
  justify-content: flex-end;
  max-width: 80%;
  float: right;
}

.ingoing {
  font-size: .9375rem;
  display: flex;
  justify-content: flex-end;
  max-width: 80%;
  float: left;
}

.outgoing-message {
  position: relative;
  background-color: #95ec69;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #031600;
  max-width: 75%;
  word-break: break-all;
  margin-top: .625rem;
  margin-right: .625rem;
  padding: 0.625rem;
  border-radius: 6px;
}

.outgoing-message::after {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #F7F7F7 transparent transparent;
  content: '\A';
  position: absolute;
  right: -6px;
  top: 14px;
  border-right-color: #95ec69;
  transform: rotateZ(180deg);
}

.incoming-message {
  position: relative;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #031600;
  padding: 0.625rem;
  max-width: 75%;
  word-break: break-all;
  margin-top: .625rem;
  margin-left: .625rem;
  border-radius: 6px;
  white-space: pre-line;
}

.incoming-message::before {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #F7F7F7 transparent transparent;
  content: '\A';
  position: absolute;
  left: -6px;
  top: 14px;
  border-right-color: #ffffff;
}

.outgoing img,
.ingoing img {
  width: 2.5rem !important;
  border-radius: .3125rem;
  box-shadow: 0px 2px 4px 0px #9e9e9e;
}

.dialog-list {
  height: 76vh;
  padding: .625rem;
  overflow: auto;
}

.requestInput {
  background: white;
  height: 2.375rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0 5px;
}