.register-content{
  background:repeating-linear-gradient(to top left,#397acc,#68c9b6);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  justify-content: center;
  align-items: center;
}
.sign-title {
  color: aliceblue;
  text-align: center;
  margin-top: 9.75rem;
  font-weight: 200;
  font-size: 1.875rem;
}
.sign-table {
  width: 80%;
  align-items: center;
  margin: 0 auto;
  margin-top: 4.4375rem
}