.login-content {
  background: repeating-linear-gradient(to top left, #397acc, #68c9b6);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  justify-content: center;
  align-items: center;
}

.title {
  color: aliceblue;
  text-align: center;
  margin-top: 10.75rem;
  font-weight: 200;
  font-size: 1.875rem;
}

.table {
  width: 80%;
  align-items: center;
  margin: 0 auto;
  margin-top: 8.4375rem
}

.login-form-forgot {
  color: #fff;
  margin: 0 1.25rem;
}

.login-form-button {
  background-color: #397bcc00;
  border: 1px solid #fff;
  border-radius: 10rem;
}

input-login {
  border-color: aliceblue;
}