body {
  padding: 0;
  margin: 0;
  /* 设置默认字体大小 */
}

h1 {
  font-size: 2em;
  /* 相对于父元素字体大小的2倍 */
}

p {
  font-size: 0.875em;
  /* 相对于父元素字体大小的0.875倍 */
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.contain {
  /* background-image: url('./assets/img/chat-bg.png'); */
  background: #ededed;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
}

.item {
  flex-basis: calc(50% - .625rem);
}

img {
  max-width: 100%;
  height: auto;
}

/* 定义在宽度小于等于768像素的设备上的样式 */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .item {
    flex-basis: 100%;
  }
}

:root:root {
  --adm-color-primary: #00adae;
}