.home-model {
  display: flex;
  flex-wrap: wrap;
}

.modeel-item {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1%;
  flex-direction: column;
  margin: 1%;
  background-color: #00adae;
  width: 29%;
}

div#scrollableDiv {
  height: 89vh;
  background: #ffffff;
}

.example-title {
  text-decoration: none;
}